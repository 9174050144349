import React from 'react'
// @ts-ignore
import Carousel from 'react-grid-carousel'
import { NavLink } from 'react-router-dom'

function OurClient() {
  return (
    <>
    <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start py-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <div className='flex justify-center items-center'>
            <h1 className="text-center font-bold font-poppins md:text-[32px] text-[27px] text-black md:leading-[50px] leading-[38px]">
            We Are Trusted By
            </h1>
          </div>

          <div className=' pt-10'>


            <Carousel cols={3} rows={1} gap={10} loop showDots>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/images.png" alt="hero" />

                </div>

              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/images1.png" alt="hero" />

                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/astrazeneca-logo-sm.png" alt="hero" />

                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/carlcare.png" alt="hero" className='w-[40%] mt-5' />

                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/Cummins_logo.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/emel.jfif" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/exphar.jfif" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/fidelity-bank.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/manipal.jfif" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/ppc.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/smile-logo.png" alt="hero" className='w-[150px]' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/sports.png" alt="hero" className='w-[150px]' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/sproxil.png" alt="hero" />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/sun-pharma.png" alt="hero" className='w-[100px]' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/TecnoMobile.png" alt="hero" className='w-[150px]' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/usaid.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/Xiaomi-Logo.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/shipbubble-logo.png" alt="shipbubble-logo" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/unilever-logo.png" alt="unilever-logo" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>


            </Carousel>



          </div>

          <div className="flex justify-center items-center pt-16">
            <div>
              <h2 className="text-center text-[32px] font-[600]">We’re dedicated to customers and focused on quality</h2>
              <h5 className="text-center text-[15px] mt-3">You can rely on us to deliver your cargo with fastest means possible giving you peace of mind.</h5>
              <NavLink to="/contact-us">
              <div className="flex justify-center items-center pt-10">
                <button className='bg-[#E04343] text-white px-[30px] py-[15px] rounded-[100px] uppercase'>Connect With Us</button>
              </div></NavLink>  
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default OurClient