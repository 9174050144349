import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
import { UserApis } from '../../apis/userApi';
import { Oval } from 'react-loader-spinner'
import { convertUTCDateToLocalDate } from '../Reusable/functions';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { toast } from 'react-toastify';
function Tracking() {

    const [trackingDetails, setTrackingDetails] = React.useState<any>('Empty');
    const [trackingId, setTrackingId] = React.useState<any>('');

    const [isMultiple, setIsMultiple] = React.useState<boolean>(false);
    const [loader, setLoader] = React.useState<boolean>(false);
    let [percentage, setPercentage] = React.useState<any>(0);
    const [allResponses, setAllResponses] = React.useState<any>([]);




    const track = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoader(true);
        let count: any = 1;

        // Convert the string to an array
        const barcodeValues = trackingId.split(",");

        // Initialize an array to hold batches
        const batches: string[][] = [];
        const aggregatedResponses = [];

        // Loop through the array and slice it into batches of 10
        for (let i = 0; i < barcodeValues.length; i += 5) {
            const batch = barcodeValues.slice(i, i + 5); // Get a batch of up to 10 items
            batches.push(batch);
        }


        console.log(batches);

        for (const batch of batches) {
            const formData = new FormData();
            formData.append('reference', batch.join(",")); // Send tracking IDs as a single string

            const response: any = await UserApis.trackShipment(formData);
            if (!response.data.status) {
                setLoader(false);
                toast.error('Err: Network error, retry.')
                throw new Error(response.data.message || 'Batch processing failed.');
                
            }
            count += 1
            allResponses.push()
            aggregatedResponses.push(response?.data?.data);

            // console?.log(pics)
            const percentage = ((count - 1) / batches.length) * 100;
            setPercentage(percentage.toFixed(1));

        }
        setTrackingDetails(aggregatedResponses?.flat());

        console?.log(aggregatedResponses?.flat())
        setPercentage(0)

        setAllResponses(aggregatedResponses?.flat())
        if (aggregatedResponses?.length > 1) {
            // Multiple results
            setIsMultiple(true);
        } else {
            // Single result
            setIsMultiple(false);
        }
        setLoader(false);

    }, [trackingId, trackingDetails]);


    return (
        <div>
            <AdminSidebar title="Tracking" />
            <div className="relative md:ml-64 bg-[#FAFAFA] ">
                <AdminNavbar title="Tracking" />

                <div className=' lg:px-44 pb-6 px-6 pt-5 bg-[#FAFAFA] min-h-screen'>
                    <div className='grid lg:grid-cols-1 grid-cols-1 gap-5'>
                        <div>
                            <form onSubmit={track}>
                                <div className='flex items-center  mx-auto'>
                                    <div className="w-full  lg:pr-4">
                                        <div className="relative w-full mb-5">
                                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                                Tracking
                                            </label>
                                            <textarea
                                                value={trackingId.split(',').join('\n')} // Display tracking IDs separated by newlines
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                    // Replace newlines with commas for backend compatibility
                                                    setTrackingId(e.target.value.split('\n').join(','));
                                                }}
                                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                rows={6} // Adjust the height by specifying the number of rows
                                                placeholder="Enter one tracking ID per line"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <button type="submit" className="inline-flex items-center py-2 px-3 ml-2 mt-1 text-sm font-medium text-white bg-[#E04343] rounded-[5px]">
                                            Track
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className='bg-white  rounded-[10px] px-[15px] py-[16px]'>
                            <h3 className='text-[14px] font-semibold'>Route Details</h3>
                            <div className={'border ' + (isMultiple ? ' h-[60vh]' : 'h-[60vh]') + ' overflow-auto border-[#E2E8F0] rounded-[15px] py-[19px] px-[30px] mt-4'}>

                                {loader ?
                                    <div className="flex flex-col  mt-[30vh] mx-5">

                                        <div className="w-full bg-gray-200 rounded-full ">
                                            <div className="bg-[#E04343] text-xs font-medium text-white text-center p-0.5 leading-none rounded-full" style={{ width: `${percentage}%` }}> {percentage}%</div>
                                        </div>

                                        <div className='flex justify-center mt-4'>
                                            <Oval
                                                visible={true}
                                                height="60"
                                                width="60"
                                                color="#E04343"
                                                secondaryColor="#FCBF94"
                                                ariaLabel="oval-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                        </div>


                                        <div className='flex justify-center mt-3'>
                                            <span>Kindly wait!!!</span>
                                        </div>

                                    </div>
                                    :

                                    isMultiple ? (
                                        // Render Table for Multiple Tracking Results
                                        <table className="w-full border-collapse border border-gray-300">
                                            <thead className="bg-gray-100">
                                                <tr>
                                                <th className="border border-gray-300 px-4 py-2">S/N</th>
                                                    <th className="border border-gray-300 px-4 py-2">Tracking Country</th>
                                                    <th className="border border-gray-300 px-4 py-2">Tracking Date</th>
                                                    <th className="border border-gray-300 px-4 py-2">Event Name</th>
                                                    <th className="border border-gray-300 px-4 py-2">Location</th>
                                                    <th className="border border-gray-300 px-4 py-2">Tracking Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {trackingDetails.map((detail: any, index: number) => (
                                                    <tr key={index}>
                                                         <td className="border border-gray-300 px-4 py-2">{index+1}</td>
                                                        <td className="border border-gray-300 px-4 py-2">{detail.TrackingCountry}</td>
                                                        <td className="border border-gray-300 px-4 py-2">{detail.TrackingDate}</td>
                                                        <td className="border border-gray-300 px-4 py-2">{detail.TrackingEventName}</td>
                                                        <td className="border border-gray-300 px-4 py-2">{detail.TrackingLocation}</td>
                                                        <td className="border border-gray-300 px-4 py-2">{detail.TrackingNumber}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        // Render Timeline for Single Tracking Result
                                        trackingDetails[0]?.TrackingDetail?.slice(0)?.reverse()?.map(
                                            (datas: any, index: number) => (
                                                <ol
                                                    key={index}
                                                    className="relative border-l-2 border-dotted border-gray-300"
                                                >
                                                    {datas?.TrackingError === '|6|No record to download.' ? (
                                                        <div className="flex justify-center">
                                                            <p className="mb-4 text-sm font-normal text-gray-400 max-w-[300px]">
                                                                No Record Found!
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <li className="ml-6">
                                                            <div className="absolute w-[24px] h-[24px] bg-red-500 rounded-full -left-[14px] border border-white"></div>
                                                            <time className="mb-1 text-base text-black">
                                                                {datas?.TrackingLocation}
                                                            </time>
                                                            <p className="mt-2 mb-1 text-sm font-normal text-gray-400 max-w-[300px]">
                                                                {datas?.TrackingEventName}
                                                            </p>
                                                            <p className="mb-4 text-sm font-normal text-gray-400 max-w-[300px]">
                                                                {datas?.TrackingDate}
                                                            </p>
                                                        </li>
                                                    )}
                                                </ol>
                                            )
                                        )
                                    )


                                }





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Tracking