import React from "react";
import { NavLink } from "react-router-dom";

function EmailVerify() {
  return (
    <>
      {/* <!-- Start block --> */}
      <section className="bg-[#FAFAFA] min-h-screen body-font font-poppins ">

        <div className="flex justify-center items-center">
          <div className="md:mx-[500px] mx-4 pt-[20px]">
            <h1 className=" text-2xl md:mt-8 mt-5 text-center text-[#0A0A0C] font-semibold leading-10 md:text-[32px] xl:text-4xl ">
              Almost Done!
            </h1>
            <p className="text-center">Kindly check your mail to verify your account with us.</p>
            <p className="text-center">Click resend after 30s </p>
            <div className="flex justify-center py-4">
              <NavLink to={'/login'}>
                <button className="text-white bg-[#E04343] rounded-[15px] transition ease-out delay-300 py-[12px] px-[42px] font-[700] text-[14px]">Close</button>
              </NavLink>
            </div>
          </div>

        </div>
        <div className="flex justify-end lg:px-[100px]">
          <div className=" ">
            <img src="/images/Auth/email-verify.png" alt="hero" />
          </div>
        </div>

      </section>

      {/* <!-- End block --> */}
    </>
  );
}

export default EmailVerify;
