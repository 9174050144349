import React from 'react'
import { NavLink, Link } from 'react-router-dom'

function Footer() {
  return (
    <>
      <section className='pt-10 md:pt-10 pb-8 bg-[#000000]'>
        <div className='grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-12 px-8 lg:px-16 pb-6'>
          <div className='col-span-2 mb-6'>
            <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>OUR OFFICE LOCATIONS</h4>
            <div className='mb-3'>
              <h1 className="text-[14px] text-[#ffffff]">HEAD OFFICE:</h1>
              <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
              <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                19/21 Balogun street, off Akinremi Street, Anifowoshe, Ikeja, Lagos, Nigeria
              </h3>
            </div>
            <div className='mb-3'>
              <h1 className="text-[14px] text-[#ffffff]">Port Harcourt</h1>
              <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
              <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                61B, Aba Road, By craft Centre Bus Stop, Beside Semec Company, PHC
              </h3>
            </div>
            <div className='mb-3'>
              <h1 className="text-[14px] text-[#ffffff]">Abuja</h1>
              <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
              <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                Suite 207, 1st Floor, Plot 336, Global Plaza, Opp. Mallam Shehu Plaza, Obafemi Awolowo Way, Jabi
              </h3>
            </div>
            <div className='mb-3'>
              <h1 className="text-[14px] text-[#ffffff]">Ibadan</h1>
              <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
              <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                Middle floor, 49 Adekunle Fajuyi road, beside Bovas filling station Adamasingba, Ibadan
              </h3>
            </div>


          </div>
          <div className='col-span-2 mb-6'>
            <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>OUR OFFICE LOCATIONS</h4>
            <div className='mb-3'>
              <h1 className="text-[14px] text-[#ffffff]">Ado-Ekiti</h1>
              <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
              <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                Shop 5, phenrose filling station complex, (formerly NNPC filling station) beside Uba, Ajilosun Ado Ekiti.
              </h3>
            </div>
            <div className='mb-3'>
              <h1 className="text-[14px] text-[#ffffff]">Abeokuta:</h1>
              <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
              <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                No.1, Onijaye Street, Off Olusegun Obasanjo way, Oke Sokori Abeokuta.
              </h3>
            </div>
            <div className='mb-3'>
              <h1 className="text-[14px] text-[#ffffff]">Osogbo:</h1>
              <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
              <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                MAO House, opp. Chicken Republic, Stadium Road, Igbona, Osogbo.
              </h3>
            </div>
            <div className='mb-3'>
              <h1 className="text-[14px] text-[#ffffff]">Akure</h1>
              <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
              <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                Lumen Christi house, opp. Mary Queen Catholic Church, 74B Hospital Road, Akure.
              </h3>
            </div>

          </div>
          <div className=' col-span-2'>
            <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>SUPPORT</h4>
            <NavLink to="/about-us">
              <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>About Us</h3>
            </NavLink>
            <NavLink to="/tracking-page">
              <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Tracking</h3>
            </NavLink>
            <NavLink to="/contact-us">
              <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Contact Us</h3>
            </NavLink>
            <NavLink to="/blog">
              <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Blog</h3>
            </NavLink>

          </div>
          <div className=' col-span-2'>
            <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6 uppercase'>Our Divisions</h4>

            <a href="https://www.skynetworldwide.com.ng/" target="_blank" rel="noopener noreferrer">
              <h3 className="mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]">CFL Global Express</h3>
            </a>
            <a href="https://www.facebook.com/CFLglobalLogistics" target="_blank" rel="noopener noreferrer">
              <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>CFL Global Logistics</h3>
            </a>
            <a href="https://skyshop-worldwide.com/" target="_blank" rel="noopener noreferrer">
              <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Skyshop</h3>
            </a>


          </div>
          <div className=' col-span-2'>
            <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>SERVICES</h4>
            <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>International & Nationwide Courier</h3>
            <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Haulage/Distribution</h3>
            <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Freight Forwarding</h3>
            <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Warehousing</h3>
            <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Procurement & Presentation</h3>
            <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Customs Clearing</h3>



          </div>
          <div className=' col-span-2'>
            <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>CONTACT</h4>
            <NavLink to="/tuition-payment">
              <h3 className=' text-[10px] md:text-[14px] font-medium text-[#ffffff]'>Phone: </h3>
              <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>07000759638, 09056533136 </h3>
            </NavLink>

            <NavLink to="/student-loan">
              <h3 className=' text-[10px] md:text-[14px] font-medium text-[#ffffff]'>Email: </h3>
              <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>enquiry@skynetworldwide.com.ng</h3>
            </NavLink>

            <NavLink to="/savings-with-afripay">
              <h3 className='text-[10px] md:text-[14px] font-medium text-[#ffffff]'>For Asos Complaints:</h3>
              <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>cs@skynetworldwide.com.ng</h3>
            </NavLink>



          </div>
        </div>
        <div className='flex justify-center px-8 lg:px-16 pb-5'>
          <p className='text-[18px] font-medium text-[#ffffff]'>Follow Us On :</p>
          <div className='flex space-x-4'>
            <a href=" https://www.facebook.com/skynet.expressng" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4z" /></svg>
            </a>

            <a href="https://www.instagram.com/skynet_expressng/?fbclid=IwZXh0bgNhZW0CMTEAAR1YWCv7ZjxxKfftgMtNJYeN8VQ9oz5WwYfIeubb8quERdMu8V6cQsnH37s_aem_klwPsxdQE2rk2IXHMa1xAA" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" fill-rule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1M12 9a3 3 0 1 0 0 6a3 3 0 0 0 0-6m-5 3a5 5 0 1 1 10 0a5 5 0 0 1-10 0" clip-rule="evenodd" /></svg>
            </a>
            <a href="https://www.tiktok.com/@skynet_expressng?lang=en" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M16 8.245V15.5a6.5 6.5 0 1 1-5-6.326v3.163a3.5 3.5 0 1 0 2 3.163V2h3a5 5 0 0 0 5 5v3a7.97 7.97 0 0 1-5-1.755" /></svg>
            </a>
            <a href="https://www.linkedin.com/company/71474870/admin/dashboard/" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M20.47 2H3.53a1.45 1.45 0 0 0-1.47 1.43v17.14A1.45 1.45 0 0 0 3.53 22h16.94a1.45 1.45 0 0 0 1.47-1.43V3.43A1.45 1.45 0 0 0 20.47 2M8.09 18.74h-3v-9h3ZM6.59 8.48a1.56 1.56 0 1 1 0-3.12a1.57 1.57 0 1 1 0 3.12m12.32 10.26h-3v-4.83c0-1.21-.43-2-1.52-2A1.65 1.65 0 0 0 12.85 13a2 2 0 0 0-.1.73v5h-3v-9h3V11a3 3 0 0 1 2.71-1.5c2 0 3.45 1.29 3.45 4.06Z" /></svg>
            </a>
            <a href="https://www.youtube.com/@skynetworldwideexpressnigeria" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" fill-rule="evenodd" d="M22.54 6.42a2.77 2.77 0 0 0-1.945-1.957C18.88 4 12 4 12 4s-6.88 0-8.595.463A2.77 2.77 0 0 0 1.46 6.42C1 8.148 1 11.75 1 11.75s0 3.602.46 5.33a2.77 2.77 0 0 0 1.945 1.958C5.121 19.5 12 19.5 12 19.5s6.88 0 8.595-.462a2.77 2.77 0 0 0 1.945-1.958c.46-1.726.46-5.33.46-5.33s0-3.602-.46-5.33M9.75 8.479v6.542l5.75-3.271z" clip-rule="evenodd" /></svg>
            </a>


          </div>

        </div>
        <hr />
        <div className='flex justify-center mt-8 px-8 lg:px-16'>
          <div>
            <p className='text-white text-[12px] md:text-[15px]'>Copyright © 2024 | SkyNet Worlwide Express Nigeria</p>
          </div>
        </div>

      </section>
    </>
  )
}

export default Footer