import React, { useState } from "react";

import Navbar from '../Navbars/Navbar'
import { NavLink } from "react-router-dom";
// @ts-ignore
import Carousel from 'react-grid-carousel'

import Footer from '../Footer';
import Testimonials from './Testimonials';
import OurClient from './OurClient';
import { useNavigate } from "react-router-dom";

function Home() {

  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputValue.trim()) {
      navigate("/tracking-page", { state: { value: inputValue } }); // Pass data via state
    }
  };

  return (
    <>
      <Navbar />
      {/* Start Section autoplay={3000} */}
      <div className='' style={{ paddingRight: '1px !important' }}>
        <Carousel cols={1} rows={1} gap={0} mobileBreakpoint={20} loop className="carousel-wrapper">
          <Carousel.Item className="carousel-slide">
            <div className="relative" >
              <img src="/images/banner1.png" alt="hero" className='w-full' />
              <div className="absolute top-1 md:top-28 lg:top-48 left-10">
                <img src="/images/mostTrusted.png" alt="hero" className='md:w-[500px] w-[200px]' />
                <div className='md:pl-7 pl-0'>
                  <h1 className="text-white text-[20px] md:text-[70px] font-black md:leading-[70px] leading-[20px] ">The Fastest Way to<br /> Send What Matters </h1>
                  <div className='md:w-[470px] w-[270px] h-[4px] bg-[#fff] md:mt-6 mt-2'></div>
                  <div className='md:pt-6 pt-2'>
                    <h3 className="text-white md:text-[30px] text-[12px] uppercase font-bold md:leading-[35px]">  Ship Now with Confidence <br />and Peace of Mind!</h3>
                  </div>

                  <NavLink to="/login">
                    <div className="md:mt-6">
                      <button className=" bg-[#E04343] text-white rounded-[8px] py-[4px]  md:py-[10px] px-[10px] md:px-[35px] text-[12px] md:text-[27px] font-bold uppercase">Ship Now</button>
                    </div>
                  </NavLink>
                </div>

              </div>
            </div>

          </Carousel.Item>
          <Carousel.Item>
            <div className="relative">
              <img src="/images/banner2.png" alt="hero" className='w-full' />
              <div className="absolute top-7 md:top-28 lg:top-48 left-10">
                <h1 className="text-white text-[40px] md:text-[70px] font-black leading-[70px]">What's The Real Cost<br /> Of Peace Of Mind? </h1>
                <div className='w-[470px] h-[4px] bg-[#fff] mt-6'></div>
                <div className='pt-6'>
                  <h3 className="text-white md:text-[30px] text-[20px] uppercase font-bold leading-[35px]">  Knowing your shipping <br />cost in seconds!</h3>
                </div>
                <NavLink to="/login">
                  <div className="mt-6">
                    <button className=" bg-green-600 text-white rounded-[8px] py-[4px]  md:py-[10px] px-[10px] md:px-[35px] text-[12px] md:text-[27px] font-bold uppercase">Calculate Rates</button>
                  </div>
                </NavLink>

              </div>
            </div>

          </Carousel.Item>
          <Carousel.Item>
            <div className="relative">
              <img src="/images/banner3.png" alt="hero" className='w-full' />
              <div className="absolute top-7 md:top-28 lg:top-48 left-10">
                <h1 className="text-white text-[40px] md:text-[70px] font-black leading-[70px]">Let Us Handle the <br />Heavy Lifting For You</h1>
                <div className='w-[470px] h-[4px] bg-[#fff] mt-6'></div>
                <div className='pt-3'>
                  <h3 className="text-white md:text-[30px] text-[20px] uppercase font-bold leading-[35px]">Sign Up Now For Exclusive, <br />customised service!</h3>
                </div>
                <NavLink to="/contact-us">
                  <div className="mt-5">
                    <button className="bg-[#FE0503] text-white rounded-[8px] py-[4px]  md:py-[10px] px-[10px] md:px-[35px] text-[12px] md:text-[27px] font-bold uppercase">Connect me with an Expert</button>
                  </div>
                </NavLink>

              </div>
            </div>

          </Carousel.Item>
          <Carousel.Item>
            <div className="relative">
              <img src="/images/banner4.png" alt="hero" className='w-full' />
              <div className="absolute top-7 md:top-28 lg:top-48 left-10">
                <h1 className="text-white text-[40px] md:text-[70px] font-black leading-[70px]">Your Shipping, <br />Your Way</h1>
                <div className='w-[380px] h-[4px] bg-white mt-3'></div>
                <div className='pt-3'>
                  <h3 className="text-white md:text-[30px] text-[20px] uppercase font-bold leading-[35px]">Schedule Your <br />pickup Immediately!</h3>
                </div>
                <NavLink to="/login">
                  <div className="mt-5">
                    <button className="bg-green-600 text-white rounded-[8px] py-[4px]  md:py-[10px] px-[10px] md:px-[35px] text-[12px] md:text-[27px] font-bold uppercase">Schedule Pickup</button>
                  </div></NavLink>

              </div>
            </div>

          </Carousel.Item>

        </Carousel>
      </div>

      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#E04343] sm:px-16 px-6 flex justify-center items-start py-2' >
        <div className=' w-full'>
          <NavLink to="/tracking-page" className="">
            <div className="flex justify-center items-center">
              <div>
                <h1 className="text-[20px] font-bold hover:font-semibold leading-[33px] text-white">Track Your Order</h1>
              </div>
            </div>
          </NavLink>
          <div>
            <form onSubmit={handleSubmit}>
              <div className='flex items-center  mx-auto md:w-2/5'>
                <div className="w-full  lg:pr-4">
                    <input
                      type="text"
                      className="bg-white border-[0.5px] border-[#ECEEF6] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#C4C4C4] placeholder:text-[12px]  ease-linear transition-all duration-150"
                      placeholder="Enter Shipment Number"
                      name="TrackingId"
                      onChange={(e: any) => setInputValue(e?.target?.value)}
                      required
                    />
                  
                </div>
                <div>
                  <button type="submit" className="inline-flex items-center py-2 px-3 ml-2 text-sm font-medium text-white bg-[#b70505] rounded-[5px]">
                    Track
                  </button>
                </div>
              </div>
            </form>
          </div>



        </div>
      </div>
      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-6 md:flex md:justify-center items-start md:py-10' >
        <div className='xl:max-w-[1200px] w-full mx-auto'>
          <div className="flex justify-center items-center">
            <div>
              <h1 className="text-[26px] font-bold leading-[33px]">Why Choose Us?</h1>
              <div className='w-[90px] h-1 bg-[#E04343] mx-auto mt-3'></div>
            </div>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-4 mt-10'>
            <div>
              <div className="flex justify-center">
                <img src="/images/truck.png" alt="hero" />
              </div>
              <h3 className="text-[17px] font-[500] text-center">Timely Delivery</h3>
            </div>

            <div>
              <div className="flex justify-center">
                <img src="/images/user.png" alt="hero" />
              </div>
              <h3 className="text-[17px] font-[500] text-center mt-5">Exceptional Customer <br /> Service </h3>
            </div>

            <div>
              <div className="flex justify-center">
                <img src="/images/calender.png" alt="hero" />
              </div>

              <h3 className="text-[17px] font-[500] text-center mt-5">Flexible</h3>
            </div>

            <div>
              <div className="flex justify-center">
                <img src="/images/hand.png" alt="hero" />
              </div>
              <h3 className="text-[17px] font-[500] text-center mt-5">Reliable</h3>
            </div>

          </div>
        </div>
      </div>
      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start md:pt-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <section className='flex md:flex-row flex-col sm:py-16 py-6'>
            <div className='flex-1 flex-col justify-center items-start  '>
              <div>
                <iframe className="w-full h-full aspect-video " src="https://www.youtube.com/embed/OXRPnjMjZ4Y?si=YOavEdmEnWwg1T5I" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
              </div>
            </div>

            <div className='flex-1 flex flex-col md:ml-[80px] ml-0  relative'>

              <div>
                <h1 className="text-[19px] font-semibold leading-[24px]">About Us</h1>
                <div className='w-[60px] h-1 bg-[#E04343] mt-3'></div>
              </div>
              <div className="mt-10">
                <h1 className="text-[28px] font-bold leading-[36.4px]">MODERN & TRUSTED <br />LOGISTICS COMPANY</h1>
                <p className="text-[16px] leading-[25px] mt-3 text-black">The SkyNet Worldwide Express network stretches across all continents, providing the Global business community with fast, reliable and secure Express delivery services to almost every country and territory Worldwide. Our Global Network of offices provides you global reach, competitive transit time, online tracking and proof of delivery.</p>
                <p className="text-[16px] leading-[25px] mt-3 text-black">What’s more, our fast, efficient and reliable global express services
                  are underpinned by a powerful and user-friendly web- based
                  system. This system features on-line tracking, proof of delivery,
                  electronic invoicing and smart dispatch systems.</p>
                <NavLink to="/about-us">
                  <div className="mt-4">
                    <button className="bg-[#E04343] text-white rounded-[100px] px-[24px] py-[12px]">
                      Learn More
                    </button>
                  </div></NavLink>

              </div>




            </div>

          </section>
        </div>
      </div>
      {/* End Section */}



      {/* Start Section */}
      <Testimonials />
      {/* End Section */}

      {/* Start Section */}
      <OurClient />
      {/* End Section */}


      <div>
        <Footer />
      </div>
      {/* End Section */}








    </>
  )
}

export default Home;