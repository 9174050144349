import React from 'react'
import Navbar from '../Navbars/Navbar';
import Footer from '../Footer';
import { UserApis } from '../../apis/userApi';
import { Oval } from 'react-loader-spinner'
import { NavLink } from 'react-router-dom';
import { useLocation } from "react-router-dom";


function TrackingPage() {

    const location = useLocation();
    const inputValue = (location.state as { value: string })?.value; // Access the passed data

    console?.log(inputValue);

    const [trackingDetails, setTrackingDetails] = React.useState<any>('Empty');
    const [trackingId, setTrackingId] = React.useState<any>(inputValue?inputValue:'');

    const [loader, setLoader] = React.useState<boolean>(false);


    const track = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoader(true);
        const formData = new FormData()
        formData.append('reference', trackingId)
        UserApis.trackShipment(formData).then(
            (response: any) => {
                if (response?.data) {
                    setTrackingDetails(response?.data?.data);
                    console.log(response?.data?.data?.TrackingDetail);
                    setLoader(false);
                }
            }
        ).catch(function (error) {
            console.log(error.response.data);
        })

    }, [trackingDetails, trackingId]);


    return (
        <>
            <Navbar />
            <div className=' lg:px-44 pb-6 px-6 pt-5 bg-[#FAFAFA] min-h-screen'>
                <div className='grid lg:grid-cols-1 grid-cols-1 gap-5'>
                    <div>
                        <form onSubmit={track}>
                            <div className='flex items-center  mx-auto'>
                                <div className="w-full  lg:pr-4">
                                    <div className="relative w-full mb-5">
                                        <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                            Tracking
                                        </label>
                                        <input
                                            type="text"
                                            className="bg-white border-[0.5px] border-[#ECEEF6] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#C4C4C4] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                            placeholder="Enter Tracking ID"
                                            name="TrackingId"
                                            value={trackingId}
                                            onChange={(e: any) => setTrackingId(e?.target?.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div>
                                    <button type="submit" className="inline-flex items-center py-2 px-3 ml-2 mt-1 text-sm font-medium text-white bg-[#E04343] rounded-[5px]">
                                        Track
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className='bg-white  rounded-[10px] px-[15px] py-[16px]'>
                        <h3 className='text-[14px] font-semibold'>Route Details</h3>
                        <div className='border h-4/5 overflow-auto border-[#E2E8F0] rounded-[15px] py-[19px] px-[30px] mt-4'>

                            {loader ?
                                <div className='flex justify-center my-3 pb-10'>
                                    <Oval
                                        visible={true}
                                        height="80"
                                        width="80"
                                        color="#E04343"
                                        secondaryColor="#FF3B300D"
                                        ariaLabel="oval-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>
                                :

                                (trackingDetails == 'Empty') ?
                                    <div className='flex justify-center'>
                                        <p className="mb-4 text-sm font-normal text-[#949DB1] max-w-[300px]">No Record Found!</p>
                                    </div>
                                    :
                                    (trackingDetails?.TrackingDetail?.slice(0)?.reverse()?.map(
                                        (datas: any, index: any) => (
                                            < ol className="relative border-l-2 border-dotted border-[#D9D9D9] ">
                                                {datas?.TrackingError == '|6|No record to download.' ?
                                                    <div className='flex justify-center'>
                                                        <p className="mb-4 text-sm font-normal text-[#949DB1] max-w-[300px]">No Record Found!</p>
                                                    </div>
                                                    :
                                                    <li className=" ml-6">
                                                        <div className="absolute w-[24px] h-[24px] bg-[#E04343] rounded-full  -left-[14px] border border-white "></div>
                                                        <time className="mb-1 text-base  text-black ">{datas?.TrackingLocation}</time>
                                                        <p className="mt-2 mb-1 text-sm font-normal text-[#949DB1] max-w-[300px]">{datas?.TrackingEventName}</p>
                                                        <p className="mb-4 text-sm font-normal text-[#949DB1] max-w-[300px]">{(datas?.TrackingDate)}</p>

                                                    </li>
                                                }

                                            </ol>
                                        )
                                    ))


                            }





                        </div>
                    </div>


                    <div className='flex'>
                        <p>Is There Any Issue?</p>
                        <NavLink to="/contact-us">
                            <span className='text-[#E04343] underline underline-offset-1 cursor-pointer font-semibold ml-2'>Talk to us</span>

                        </NavLink>

                    </div>

                </div>
            </div>

            {/* Start Section */}
            <Footer />
            {/* End Section */}
        </>
    )
}

export default TrackingPage