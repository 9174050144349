import React, { useState } from "react";
import Navbar from '../Navbars/Navbar'
import Footer from '../Footer';
import { Link, NavLink } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { AuthLiveApis } from "../../apis/live/authLiveApis";
import { AuthApis } from "../../apis/authApis";
import { History } from "history";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Oval } from "react-loader-spinner";

function ContactUs() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  // How to access the redux store
  const userLoginData = useSelector((state: any) => state.data.login.value);

  // This is used to update the store
  const dispatch: Dispatch = useDispatch();

  const handleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoader(true)
      const formData = new FormData()
      formData.append('first_name', firstName)
      formData.append('last_name', lastName)
      formData.append('email', email)
      formData.append('phone_number', phoneNumber)
      formData.append('message', message)

      AuthApis.contactUs(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              toast.success('Information Sent Successfully');
              setLoader(false);
            }
          } else {
            setLoader(false)
            toast.warn('Something went wrong');
          }

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        setLoader(false)
        console.log(error.response.data);
        toast.error("Offfline");
      }).finally(() => {
        setLoader(false);
        // toast.error("No Internet Connection");

      });
    },
    [email, firstName, lastName, phoneNumber, message, loader]
  );

  return (
    <>
      <section className=" bg-gradient-to-r from-[#FFFFFF] to-[#f8f1f1]">
        <Navbar />
        <div className="pt-[120px] md:pt-[80px] pb-20">
          <div className="flex flex-col justify-center items-center px-8">
            <h1 className="text-[30px]  font-bold  tracking-normal  md:text-[64px] xl:text-[64px]">
              Contact Us
            </h1>
            <p className="text-[12px] md:text-[16px] text-[#A6A6A6] text-center">
              To connect with our Sales Experts or inquire about our shipping services and partnership, kindly fill the form below.
            </p>
          </div>
          <div className="mt-14 px-6">
            <div className="rounded-[20px] bg-transparent p-2 md:mx-[95px] shadow-lg">
              <div className="flex lg:flex-row flex-col lg:justify-between gap-10">
                <div className="bg-[#E04343] text-white rounded-[10px] md:w-[500px] lg:col-span-5 lg:px-8 px-4 py-6">
                  {/* <h3 className="md:text-[32px] text-[20px] font-bold">
                    Send Message
                  </h3> */}
                  <p className="md:text-[28px] text-[20px] text-white font-bold mt-1">
                    For request and complaints
                  </p>
                  <div className="mt-10">
                    <div className="flex space-x-4 mb-4">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z"
                          fill="white"
                        />
                      </svg>
                      <div>

                        <p className="text-[12px] md:text-[14px] text-white font-normal">
                          07000759638
                        </p>
                      </div>
                    </div>
                    <div className="flex space-x-4 mb-4">
                      <svg
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5769 3.23077H2.42308C1.05 3.23077 0 4.28077 0 5.65385V15.3462C0 16.7192 1.05 17.7692 2.42308 17.7692H18.5769C19.95 17.7692 21 16.7192 21 15.3462V5.65385C21 4.28077 19.95 3.23077 18.5769 3.23077ZM19.2231 15.6692L12.9231 11.1462L10.5 12.7615L7.99615 11.1462L1.77692 15.6692L6.86538 10.4192L0.646154 5.57308L10.5 10.9039L20.2731 5.65385L14.1346 10.5L19.2231 15.6692Z"
                          fill="white"
                        />
                      </svg>
                      <p className="text-[12px] md:text-[14px] text-white font-normal">
                        cs@skynetworldwide.com.ng
                      </p>
                    </div>
                    <div className="flex space-x-4 mb-4">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_512_5455)">
                          <path
                            d="M12 2C14.3869 2 16.6761 2.94821 18.364 4.63604C20.0518 6.32387 21 8.61305 21 11C21 14.074 19.324 16.59 17.558 18.395C16.6755 19.2869 15.7128 20.0956 14.682 20.811L14.256 21.101L14.056 21.234L13.679 21.474L13.343 21.679L12.927 21.921C12.6445 22.0818 12.325 22.1663 12 22.1663C11.675 22.1663 11.3555 22.0818 11.073 21.921L10.657 21.679L10.137 21.359L9.945 21.234L9.535 20.961C8.42298 20.2083 7.38707 19.3489 6.442 18.395C4.676 16.588 3 14.074 3 11C3 8.61305 3.94821 6.32387 5.63604 4.63604C7.32387 2.94821 9.61305 2 12 2ZM12 8C11.606 8 11.2159 8.0776 10.8519 8.22836C10.488 8.37913 10.1573 8.6001 9.87868 8.87868C9.6001 9.15726 9.37913 9.48797 9.22836 9.85195C9.0776 10.2159 9 10.606 9 11C9 11.394 9.0776 11.7841 9.22836 12.1481C9.37913 12.512 9.6001 12.8427 9.87868 13.1213C10.1573 13.3999 10.488 13.6209 10.8519 13.7716C11.2159 13.9224 11.606 14 12 14C12.7956 14 13.5587 13.6839 14.1213 13.1213C14.6839 12.5587 15 11.7956 15 11C15 10.2044 14.6839 9.44129 14.1213 8.87868C13.5587 8.31607 12.7956 8 12 8Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_512_5455">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <div>

                        <p className="text-[12px] md:text-[14px] text-white font-normal">
                          19/21 Balogun street, off Akinremi Street, Anifowoshe, Ikeja, Lagos, Nigeria
                        </p>
                      </div>
                    </div>

                  </div>


                </div>
                <div className="lg:col-span-7">
                  <div className="">
                    <form onSubmit={handleSubmit}>
                      <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-8">
                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                          <div className="flex-auto px-5   py-10 pt-0">
                            <div className="flex flex-wrap ">
                              <div className="w-full lg:w-6/12 lg:pr-4">
                                <div className="relative z-0 w-full mb-10 group">
                                  <input
                                    type="text"
                                    name="first_name"
                                    onChange={(e: any) => setFirstName(e.target.value)}
                                    id="first_name"
                                    className="block py-2.5 px-0 w-full text-sm text-[#E04343] bg-transparent border-0 border-b-2 border-[#D9D9D9] appearance-none focus:outline-none focus:ring-0 focus:border-[#777777] peer"
                                    placeholder=" "
                                    required
                                  />
                                  <label
                                    htmlFor="first_name"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#777777] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                  >
                                    First Name
                                  </label>
                                </div>
                              </div>
                              <div className="w-full lg:w-6/12 ">
                                <div className="relative z-0 w-full mb-10 group">
                                  <input
                                    type="text"
                                    name="last_name"
                                    onChange={(e: any) => setLastName(e.target.value)}
                                    id="last_name"
                                    className="block py-2.5 px-0 w-full text-sm text-[#E04343] bg-transparent border-0 border-b-2 border-[#D9D9D9] appearance-none  focus:outline-none focus:ring-0 focus:border-[#777777] peer"
                                    placeholder=" "
                                    required
                                  />
                                  <label
                                    htmlFor="last_name"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#777777] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                  >
                                    Last Name
                                  </label>
                                </div>
                              </div>
                              <div className="w-full lg:w-6/12 lg:pr-4">
                                <div className="relative z-0 w-full mb-10 group">
                                  <input
                                    type="email"
                                    name="email"
                                    onChange={(e: any) => setEmail(e.target.value)}
                                    id="email"
                                    className="block py-2.5 px-0 w-full text-sm text-[#E04343] bg-transparent border-0 border-b-2 border-[#D9D9D9] appearance-none   focus:outline-none focus:ring-0 focus:border-[#777777] peer"
                                    placeholder=" "
                                    required
                                  />
                                  <label
                                    htmlFor="email"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#777777] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                  >
                                    Email Address
                                  </label>
                                </div>
                              </div>
                              <div className="w-full lg:w-6/12 ">
                                <div className="relative z-0 w-full mb-10 group">
                                  <input
                                    type="number"
                                    name="phone_number"
                                    id="phone_number"
                                    onChange={(e: any) => setPhoneNumber(e.target.value)}
                                    className="block py-2.5 px-0 w-full text-sm text-[#E04343] bg-transparent border-0 border-b-2 border-[#D9D9D9] appearance-none  focus:outline-none focus:ring-0 focus:border-[#777777] peer"
                                    placeholder=" "
                                    required
                                  />
                                  <label
                                    htmlFor="phone_number"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#777777] peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                  >
                                    Phone Number
                                  </label>
                                </div>
                              </div>
                              <div className="w-full lg:w-12/12 ">
                                <div className="relative z-0 w-full mb-10 group">
                                  <input
                                    type="text"
                                    name="message"
                                    id="message"
                                    className="block py-2.5 px-0 w-full text-sm text-[#E04343] bg-transparent border-0 border-b-2 border-[#D9D9D9] appearance-none   focus:outline-none focus:ring-0 focus:border-[#777777] peer"
                                    placeholder=" "
                                    onChange={(e: any) => setMessage(e.target.value)}
                                    required
                                  />
                                  <label
                                    htmlFor="message"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#777777] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                  >
                                    Message
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-5">
                        <button
                          type="submit"
                          disabled={loader}
                          className="w-full text-white bg-[#E04343]  font-medium rounded-[50px] text-sm px-5 py-3"
                        >
                          <div className="flex justify-center">
                            <span className="mr-3"> Send Message</span>

                            {loader ?
                              <Oval
                                visible={true}
                                height="18"
                                width="18"
                                color="#E04343"
                                secondaryColor="white"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                              :
                              ''
                            }

                          </div>

                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />

      {/* <!-- Footer --> */}
      <Footer />
    </>
  )
}

export default ContactUs