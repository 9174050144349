import React from 'react'
import Navbar from '../Navbars/Navbar';
import Testimonials from './Testimonials';
import OurClient from './OurClient';

import Footer from '../Footer';

function Services() {
    return (
        <>
            <Navbar />

            <section className='flex md:flex-row flex-col'>
                <div className='flex-1 flex justify-center items-center'>
                    <img src="/images/services.png" alt="services" />
                </div>
            </section>


            {/* Start Section */}
            <div className='bg-[#FFFFFF] sm:px-16 px-6 md:flex md:justify-center items-start py-10' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    <div className="flex justify-center items-center">
                        <div>
                            <h1 className="text-[30px] font-bold leading-[33px]">Our Process</h1>
                            <div className='w-[55px] h-[2px] bg-[#000000] mx-auto mt-3'></div>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 mt-10'>


                        <div>
                            <div className="flex justify-center">
                                <img src="/images/process1.png" alt="hero" />
                            </div>
                            <h3 className="text-[24px] font-[700] text-[#282828] text-center mt-5">Order Shipping </h3>
                            <p className='text-center mt-4 text-[14px] font-[400] text-[#60392F]'>Drop your package or send your address for pickup. Dispatch will come for the pickup</p>
                        </div>

                        <div>
                            <div className="flex justify-center">
                                <img src="/images/process2.png" alt="hero" />
                            </div>

                            <h3 className="text-[24px] font-[700] text-[#282828] text-center mt-5">Track Your Order</h3>
                            <p className='text-center mt-4 text-[14px] font-[400] text-[#60392F]'>The item is keyed into the site for tracking. You will get a tracking number to help you get the process of delivery in transit.</p>
                        </div>

                        <div>
                            <div className="flex justify-center">
                                <img src="/images/process3.png" alt="hero" />
                            </div>
                            <h3 className="text-[24px] font-[700] text-[#282828] text-center mt-5">Express Delivery</h3>
                            <p className='text-center mt-4 text-[14px] font-[400] text-[#60392F]'>Get your package delivered timely and safe.</p>
                        </div>

                    </div>
                </div>
            </div>
            {/* End Section */}



            {/* Start Section */}
            <div className='bg-[#E8E7ED] sm:px-16 px-6 md:flex md:justify-center items-start pt-10 pb-16' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    <div className='flex justify-center items-center'>
                        <h1 className="text-center font-bold font-poppins md:text-[40px] text-[27px] text-[#282828] md:leading-[50px] leading-[38px]">
                            What We Offer
                        </h1>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 mt-10'>
                        <div className='bg-white pt-[30px] pb-[20px] px-[33px]'>
                            <h3 className='text-[#FC0505] text-[24px] font-bold'>International & Nationwide Courier</h3>
                            <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We are licensed by Courier regulatory Department to undertake delivery of mails and parcels locally and internationally.</p>
                            <p className='mt-4 text-[#000000] text-[16px] md:leading-[25px]'>Our services are divided into two broad categories: namely, domestics and international mails services.</p>
                            <p className='mt-4 text-[#000000] text-[16px] md:leading-[25px]'>We specialize in the area of intra and inter-city and nationwide delivery of mails and parcels. We also deliver bulk mails such as dividend warrants, Share certificates and Annual general meeting notices and brochures and bank stationery and consumables. We deliver mail and parcels to and from over 200 cities around the world.</p>
                            <p className='mt-4 text-[#000000] text-[16px] md:leading-[25px]'>We are equipped with Track and Trace system for real-time tracking of shipments.</p>
                        </div>

                        <div className='bg-white pt-[30px] pb-[20px] px-[33px]'>
                        <h3 className='text-[#FC0505] text-[24px] font-bold'>Haulage/Distribution</h3>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We can undertake distribution of your cargo by our fleet of trucks to any destination within Nigeria when regular local flights are not the solution to your logistics needs.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We operate distribution services to numerous destinations within Nigeria and other West African States. We have dedicated fleets of truck that we make available for hire either on one-off basis of long or short term contract basis.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>Our distribution / supply chain service has recorded acclaimed success nationwide.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We have on our client list manufacturer of time bound food and pharmaceutical products. We are logistic provider to many manufacturing companies. We have in our fleet various trucks with capacities such as 5 tons, 10 tons, 15 and 30 tons.We have adequate Goods in Transit Insurance (GIT) cover.</p>
                        </div>

                        <div className='bg-white pt-[30px] pb-[20px] px-[33px]'>
                        <h3 className='text-[#FC0505] text-[24px] font-bold'>FREIGHT FORWARDING</h3>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We specialize in shipping of cargo of any size or weight by air or sea from any part of the world with efficiency and reliability that is superior in the international freight transportation industry.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We have agent/ partners in about 209 cities around the world operating from over 1000 offices around the world. Our service is either on pre-paid or collect basis. You can rely on us to deliver your cargo with fastest means possible giving you peace of mind.</p>
                        
                        </div>

                        <div className='bg-white pt-[30px] pb-[20px] px-[33px]'>
                        <h3 className='text-[#FC0505] text-[24px] font-bold'>WAREHOUSING</h3>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We offer warehousing service to our numerous clients by providing them with necessary facilities to aid their business and inventory. We act as inventory managers to banks and help to monitor performance of loans advanced to their client to import stocks and equipment. We manage warehouses for the clients and execute their release order whenever such is giving. We deploy latest information technology to execute or services.</p>
                        
                        </div>

                        <div className='bg-white pt-[30px] pb-[20px] px-[33px]'>
                        <h3 className='text-[#FC0505] text-[24px] font-bold'>CUSTOMS CLEARING</h3>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We are also into custom clearing both in Apapa and at Murtala Mohammed International Airport (Cargo Section). We work to make sure that all needed documentations are ready and in order even before the arrival of goods.</p>
                        
                        </div>
                        <div className='bg-white pt-[30px] pb-[20px] px-[33px]'>
                        <h3 className='text-[#FC0505] text-[24px] font-bold'>PROCUREMENT & REPRESENTATION</h3>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We can undertaken procurement of goods from foreign suppliers on behalf of our clients. We have the ability to make payments for goods and services in situations our customers are unable to do so.</p>
                        
                        </div>

                    </div>
                </div>
            </div>
            {/* End Section */}

 {/* Start Section */}
 <Testimonials />
      {/* End Section */}

      {/* Start Section */}
      <OurClient />
      {/* End Section */}

      {/* Start Section */}
      <Footer />
      {/* End Section */}
        </>
    )
}

export default Services